import Vue from 'vue'
import VueRouter from 'vue-router'
import {getAccessToken} from '@/plugins/storage'
import authService from '@/services/auth'

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		// will match everything
		path: '/404',
		name: '404',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'home',
		redirect: '/billing',
	},
	{
		path: '/profile',
		name: 'profile',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			titleCode: 'sidebarMenu.account',
		},
		component: () => import('../views/auth/profile.vue'),
	},
	{
		path: '/sign-in',
		name: 'signIn',
		component: () => import('../views/auth/signIn.vue'),
	},
	{
		path: '/sign-up',
		name: 'signUp',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/auth/signUp.vue'),
	},
	{
		path: '/forgot',
		name: 'forgot',
		meta: {
			layoutClass: 'layout-forgot',
		},
		component: () => import('../views/auth/forgot.vue'),
	},
	{
		path: '/forgot/:token',
		name: 'resetPass',
		meta: {
			layoutClass: 'layout-resetPass',
		},
		component: () => import('../views/auth/resetPass.vue'),
	},
	// Services
	{
		path: '/servers',
		name: 'servers',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.server',
		},
		component: () => import('../views/server/index.vue'),
	},
	{
		path: '/servers/create',
		name: 'server.create',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.server',
		},
		component: () => import('../views/server/create.vue'),
	},
	{
		path: '/servers/detail/:id',
		name: 'server.detail',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.server',
		},
		component: () => import('../views/server/detail.vue'),
	},
	{
		path: '/volumes',
		name: 'volumes',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.volume',
		},
		component: () => import('../views/volume/index.vue'),
	},
	{
		path: '/volumes/create',
		name: 'volume.create',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.volume',
		},
		component: () => import('../views/volume/create.vue'),
	},
	{
		path: '/networks',
		name: 'networks',
		layout: "dashboard",
		component: () => import('../views/network/index.vue'),
	},
	{
		path: '/snapshots',
		name: 'snapshots',
		layout: "dashboard",
		component: () => import('../views/snapshot/index.vue'),
	},
	{
		path: '/floating-ips',
		name: 'floatingIP',
		layout: "dashboard",
		component: () => import('../views/network/floatingIP/index.vue'),
	},
	{
		path: '/floating-ips/create',
		name: 'floatingIP.create',
		layout: "dashboard",
		component: () => import('../views/network/floatingIP/create.vue'),
	},
	{
		path: '/snapshots/create',
		name: 'snapshot.create',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.snapshot',
		},
		component: () => import('../views/snapshot/create.vue'),
	},
	{
		path: '/sshkeys',
		name: 'sshkeys',
		layout: "dashboard",
		component: () => import('../views/sshKey/index.vue'),
	},
	{
		path: '/security-groups',
		name: 'securityGroups',
		layout: "dashboard",
		component: () => import('../views/securityGroup/index.vue'),
	},
	// Billing
	{
		path: '/billing',
		name: 'billing',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.billing',
		},
		component: () => import('../views/billing/index.vue'),
	},
	{
		path: '/billing/:id',
		name: 'billing.detail',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.billing',
		},
		component: () => import('../views/billing/detail.vue'),
	},
	{
		path: '/billing/payment/:id',
		name: 'billing.payment',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.billing',
		},
		component: () => import('../views/billing/payment.vue'),
	},
	{
		path: '/payment',
		name: 'payment',
		layout: "dashboard",
		component: () => import('../views/payment/index.vue'),
	},
	{
		path: '/tickets',
		name: 'tickets',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.ticket',
		},
		component: () => import('../views/ticket/index.vue'),
	},
	{
		path: '/tickets/:id',
		name: 'tickets.chat',
		layout: "dashboard",
		meta: {
			titleCode: 'sidebarMenu.ticket',
		},
		component: () => import('../views/ticket/chat.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach(async (to, from, next) => {
	const not_auth = ['signIn', 'signUp','forgot','resetPass'];

	// Nếu còn token
	if (!Vue.lodash.isEmpty(getAccessToken())) {
		// Nếu vào các page sau thì đẩy về trang chủ
		if (not_auth.includes(to.name)) {
			next({ path: '/' });
		}
		// Check token
		authService.check();
	} else {
		if (!not_auth.includes(to.name)) {
			next({ path: 'sign-in' });
		}
	}
  	next();
});

export default router
